import React from "react"
import { graphql } from "gatsby"
import { document } from "browser-monads"
import "semantic-ui-css/semantic.min.css"

import Header from "../components/header"
import SEO from "../components/seo"
import Contact from "../components/contact"
import VideoBanner from "../components/videoBanner"
import DisplayCard from "../components/displayCard"

import meetingVid from "../assets/video/meeting.mp4"
import meetingImg from "../assets/img/projects.jpg"

export const pageQuery = graphql`
  query {
    allWpPost {
      nodes {
        title
        excerpt
        slug
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          sourceUrl
        }
        gallery {
          gallery {
            sourceUrl
          }
        }
        tags {
          nodes {
            name
          }
        }
      }
    }
  }
`

// Reset Scroll
document.body.scrollTo(0, 0)

const FreewareIndexPage = ({ data }) => {
  return (
    <div className="wave-pattern-bg">
      <VideoBanner
        text="Our Freeware"
        videoSrc={meetingVid}
        imageSrc={meetingImg}
      />
      <Header color="white" />
      <div className="index-bg-wrap">
        <div className="card-wrap index">
          {data.allWpPost.nodes
            .filter(post => {
              if (post.categories.nodes[0].name === "Our Freeware") {
                return post
              }
            })
            .map((post, index) => (
              <DisplayCard key={index} props={post} />
            ))}
        </div>
      </div>
      <Contact />
    </div>
  )
}

export default FreewareIndexPage
